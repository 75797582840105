import moment from 'moment';

import Types from '../../types';
import * as KlueTypes from '../../klue_types';
import {fetchCraftData} from './data_providers';

export function employeeCount(company, craftFetcher = fetchCraftData) {
  if(!KlueTypes.Company.validate(company)) {
    console.warn('Unexpected parameter type.', company);
    throw new Error('Parameter to employeeCount was unexpected type.');
  }

  return craftFetcher(company.info).then(data => {
    const employeeData = data.employees;
    const events = [];

    const lastDate = new Date(Math.max.apply(null, employeeData.map(o => new Date(o.date))));

    for(let i = 0, len = employeeData.length; i < len; i++) {
      const entry = employeeData[i];

      // filter out points that are 18months older than the last point
      if(moment(entry.date).isAfter(moment(lastDate).subtract(18, 'month'))) {
        const date = KlueTypes.Date.create(moment(entry.date));

        events.push(KlueTypes.TimeseriesEvent.create({date, value: entry.employeeNumber}));
      }
    }

    return KlueTypes.Timeseries.create({
      label: data.displayName,
      events: Types.arrayOf(KlueTypes.TimeseriesEvent).create(events)
    });
  });
}
