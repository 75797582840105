import Types from '../../types';
import * as KlueTypes from '../../klue_types';
import {fetchCraftData} from './data_providers';

export function keyPeople(company, craftData = fetchCraftData) {
  if(!KlueTypes.Company.validate(company)) {
    console.warn('Unexpected parameter type.', company);
    throw new Error('Parameter was unexpected type.');
  }

  return craftData(company.info).then(data => {
    const {keyExecutives} = data;

    if(keyExecutives.length === 0) {
      throw new Error('Data unavailable.');
    }

    const elems = keyExecutives.map(exec => {
      const {name, title, headshot, linkedin, twitter} = exec;
      const headshotUrl = headshot ? headshot.url : null;

      return KlueTypes.Person.create({
        name,
        position: title,
        imageUrl: headshotUrl,
        linkedinUrl: linkedin || null,
        twitterUrl: twitter || null
      });
    });

    return Types.arrayOf(KlueTypes.Person).create(elems);
  });
}
