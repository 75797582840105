import DashboardGroup from './_dashboard_group';
import DashboardCard from './_dashboard_card';
import MenuWrapper from './_menu_wrapper';
import CompanyLogo from './_company_logo';
import Observer from './_observer';

// layout components
import Page from './layout/_page';
import Grid from './layout/_grid';

import {isValidId, wait} from '../modules/utils';
import {isMSIE} from '../modules/browser_utils';
import {pluralize} from '../modules/text_utils';
import {userCanCurate} from '../modules/roles_utils';
import {dimensions, breakpoints, uiDelays, uiDashboardColsMSIE} from '../modules/constants/ui';
import {RIVALS_LIMIT_UNLIMITED} from '../modules/constants/api';

import classNames from 'classnames';
import moment from 'moment';
import {throttle} from 'lodash';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

class DashboardView extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    history: PropTypes.object,
    user: PropTypes.object,
    rivals: PropTypes.arrayOf(PropTypes.object),
    totalRivals: PropTypes.number,
    rivalGroups: PropTypes.arrayOf(PropTypes.object),
    rivalsLoaded: PropTypes.bool,
    rivalGroupsLoaded: PropTypes.bool,
    activeGroup: PropTypes.object,
    showGroupings: PropTypes.bool,
    rivalsOrder: PropTypes.string,
    activeGroupId: PropTypes.number,
    companyFilter: PropTypes.string,
    collapsedGroups: PropTypes.arrayOf(PropTypes.number),
    onToggleGroupMembership: PropTypes.func,
    onToggleGroup: PropTypes.func,
    onDisplayChange: PropTypes.func,
    updateRival: PropTypes.func
  };

  static defaultProps = {
    history: {},
    user: null,
    rivals: null,
    totalRivals: 0,
    rivalGroups: [],
    rivalsLoaded: false,
    rivalGroupsLoaded: false,
    activeGroup: null,
    showGroupings: false,
    rivalsOrder: '',        // empty string if user/company defaults haven't been loaded yet
    activeGroupId: null,    // current group filter: > 0 = valid groupId, 0 = all companies, -1 = all groups
    companyFilter: '',
    collapsedGroups: [],
    onToggleGroupMembership() {},
    onToggleGroup() {},
    onDisplayChange() {},
    updateRival() {}
  };

  state = {
    page: 1,
    placeholderCount: 0,
    groupsLoaded: [],
    cardWidth: 0,
    cardHeight: 0
  };

  componentDidMount() {
    console.log('DashboardView.componentDidMount: props: %o', this.props);

    this._isMounted = true;

    const {showGroupings, rivalsOrder, activeGroupId} = this.props;

    this.throttledResizeCardDims = throttle(this.getCardDimensions, 50);

    window.addEventListener('resize', this.throttledResizeCardDims);

    this.getCardDimensions();

    if(!showGroupings) {
      this.throttledResizePHCounts = throttle(this.getPlaceholderCount, 50);

      window.addEventListener('resize', this.throttledResizePHCounts);

      // needed in case we're coming back to the "all companies" view from another page after an initial dashboard load
      if(!activeGroupId) {
        this.getPlaceholderCount().then(placeholderCount => {
          // if the order is already set, we've coming to the dashboard "all companies" view for the first time via edit mode first
          if(rivalsOrder) {
            const rivalOptions = {
              order: rivalsOrder,
              page: 1,
              limit: placeholderCount || RIVALS_LIMIT_UNLIMITED
            };

            this.context.api.rivalsGet(rivalOptions);
          }
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const {activeGroupId, activeGroup, rivalsOrder, showGroupings, companyFilter} = this.props;
    const {
      activeGroup: nextActiveGroup,
      activeGroupId: nextActiveGroupId,
      rivalsOrder: nextRivalsOrder,
      companyFilter: nextCompanyFilter,
      showGroupings: nextShowGroupings
    } = nextProps;

    if((nextRivalsOrder === '') || (nextActiveGroupId === null)) {
      // props/parent state isn't fully initialized yet, skip rivals/placeholders load
      return;
    }

    if(activeGroup && nextShowGroupings) {
      // mark activeGroup as loaded when switching views between a single group and the "all groups" view
      this.handleGroupLoaded(activeGroup);
    }

    const isOrderChanged = rivalsOrder !== nextRivalsOrder;
    const isGroupChanged = activeGroupId !== nextActiveGroupId;
    const isGroupingsChanged = showGroupings !== nextShowGroupings;
    const isFiltering = companyFilter !== nextCompanyFilter;
    const isPaginatedView = !nextShowGroupings && !nextActiveGroup && !isFiltering;

    // reset everything when switching dashboard contexts or adding/removing rivals (breaks placeholder/progressive loading otherwise)
    if(isOrderChanged || isGroupChanged || isGroupingsChanged || isFiltering) {
      this.setState({
        page: 1,
        placeholderCount: 0
      });

      this.resetViewport();

      // kick-off loading first batch of rivals
      this.getPlaceholderCount(nextProps).then(placeholderCount => {
        if(!nextShowGroupings || nextCompanyFilter) {
          // makes things a little janky (i.e. new items loading/sorting-in mid-set) when including previously loaded rivals
          const rivalOptions = {
            order: nextRivalsOrder
          };
          const filter = (nextCompanyFilter || '').trim();

          if(filter) {
            rivalOptions.query = filter;
          }

          if(nextActiveGroup) {
            // retrieve all rivals for currently selected group
            rivalOptions.rivalGroupId = nextActiveGroup.id;
          }

          // retrieve paginated view; loads more on scroll
          // TODO: paginate the "all groups" view at some point, specifically when dealing with large groups
          if(isPaginatedView || showGroupings || isGroupChanged) {
            Object.assign(rivalOptions, {
              page: 1,
              limit: placeholderCount || RIVALS_LIMIT_UNLIMITED
            });
          }

          nextProps.onDisplayChange().then(() => nextContext.api.rivalsGet(rivalOptions));
        }
      }).catch(error => console.warn('DashboardView.componentWillReceiveProps: grid not ready: %o', error));
    }

    if(isGroupingsChanged || isGroupChanged) {
      if(nextShowGroupings || nextActiveGroupId) {
        window.removeEventListener('resize', this.throttledResizePHCounts);
      }
      else {
        window.addEventListener('resize', this.throttledResizePHCounts);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {rivals: prevRivals, rivalGroups: prevRivalGroups, totalRivals: prevTotalRivals, showGroupings: prevShowGroupings} = prevProps;
    const {rivals, rivalGroups, totalRivals, showGroupings, rivalsOrder, activeGroupId} = this.props;
    let groupRivals = [];
    let prevGroupRivals = [];
    let rivalAdded = false;

    if((rivalsOrder === '') || (activeGroupId === null)) {
      // props/parent state isn't fully initialized yet, skip any scrolling
      return;
    }

    // strictly check that only one rival was added (ignore pagination/other influences on totals)
    // NOTE: deletion only happens from within a board, so other checks should catch that action and refresh
    if(showGroupings) {
      if(!_.isEmpty(rivalGroups) && !_.isEmpty(prevRivalGroups)) {
        // NOTE: AppBase.state.totalResults is set to the total rivals in each group when pulling individual groups, so ignore it
        const ungroupedRivals = (rivalGroups.find(r => !r.id) || {}).rivals || [];
        const prevUngroupedRivals = (prevRivalGroups.find(r => !r.id) || {}).rivals || [];

        rivalAdded = (ungroupedRivals.length - prevUngroupedRivals.length) === 1;
      }
    }
    else if(activeGroupId > 0) {
      if(!_.isEmpty(rivalGroups) && !_.isEmpty(prevRivalGroups)) {
        groupRivals = (rivalGroups.find(r => r.id === activeGroupId) || {}).rivals || [];
        prevGroupRivals = (prevRivalGroups.find(r => r.id === activeGroupId) || {}).rivals || [];

        rivalAdded = (groupRivals.length - prevGroupRivals.length) === 1;
      }
    }
    else {
      rivalAdded = prevRivals && rivals && ((totalRivals - prevTotalRivals) === 1);
    }

    if(rivalAdded) {
      const isGroupingsChanged = prevShowGroupings !== showGroupings;
      const isGroupsLoaded = !_.isEmpty(rivalGroups) && ((this.state.groupsLoaded || []).length === (rivalGroups || []).length);

      // added or removed rivals
      this.getPlaceholderCount().then(async () => {
        // make sure all groups are loaded before scrolling if we're on the "all groups" view
        if(!isGroupingsChanged || isGroupsLoaded) {
          await wait(uiDelays.dashboardScrollOnNewCompany);

          if(showGroupings) {
            // if we're on the "all groups" view, scroll to the "ungrouped companies" group instead
            return this.scrollToGroup(0);
          }

          let newRivals = [];

          // if we're in a specific group, it will have been reloaded when adding the new company,
          // so different logic for finding the newly added rival is required
          if(activeGroupId > 0) {
            newRivals = groupRivals.filter(r => !prevGroupRivals.find(pr => pr.id === r.id));
          }
          else {
            newRivals = rivals.filter(r => !prevRivals.find(pr => pr.id === r.id));
          }

          // on any other view
          newRivals.length && this.scrollToRival(newRivals[0].id);
        }
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledResizeCardDims);
    window.removeEventListener('resize', this.throttledResizePHCounts);

    this._isMounted = false;
  }

  _cardRefs = {};

  _setCardRef = (rivalId, node) => this._cardRefs[rivalId] = node;

  _preventDefault = event => event && event.preventDefault();

  resetViewport = () => {
    // reset scroll position so as not to mess with viewport/observer calcs when switching dashboard views
    const viewport = document.querySelector('.layout-page > div:first-child');

    if(viewport) {
      viewport.scrollTop = 0;
    }
  };

  scrollToGroup = groupId => {
    if(!isValidId(groupId, true)) {
      return;
    }

    const groupEl = document.querySelector(`div.dashboard-group-${groupId}`);

    groupEl && groupEl.scrollIntoView({behavior: 'smooth', block: 'start'});
  };

  scrollToRival = rivalId => {
    if(!isValidId(rivalId)) {
      return;
    }

    const cardEl = ReactDOM.findDOMNode(this._cardRefs[rivalId]);

    cardEl && cardEl.scrollIntoView({behavior: 'smooth', block: 'start'});
  };

  getRowDetails = () => {
    const grid = document.querySelector('div.layout-grid');

    if(!grid) {
      console.log('DashboardView.getRowDetails: dashboard grid is not loaded');

      return {cardsPerRow: 0, rowGap: 0};
    }

    const gridStyles = window.getComputedStyle(grid);
    const ieVersion = isMSIE();
    let cardsPerRow = 0;
    let rowGap = 0;

    if(ieVersion && (ieVersion <= 11)) {
      // NOTE: .layout-grid container is reformatted as display: table for <= IE11, so requires specific calcs
      if(window.matchMedia('(min-width: 1280px)').matches) {
        cardsPerRow = uiDashboardColsMSIE.BREAKPOINT_1280;
      }
      else if(window.matchMedia('(min-width: 800px)').matches) {
        cardsPerRow = uiDashboardColsMSIE.BREAKPOINT_800;
      }
      else if(window.matchMedia('(min-width: 680px)').matches) {
        cardsPerRow = uiDashboardColsMSIE.BREAKPOINT_680;
      }

      // grid-row-gap fixed at 4px
      rowGap = 4;

      return {cardsPerRow, rowGap};
    }

    cardsPerRow = (gridStyles.getPropertyValue('grid-template-columns') || '').split(' ').filter(Boolean).length;
    rowGap = parseInt(gridStyles.getPropertyValue('grid-row-gap').replace('px', ''), 10);

    return {cardsPerRow, rowGap};
  };

  getPageAndCardHeight = () => {
    let page;
    let cardHeight;

    if(window.matchMedia(`(max-width: ${breakpoints.dashboardMobileMax}px)`).matches) {
      // mobile condensed dashboard view at <= 520px width
      page = document.querySelector('body');
      cardHeight = dimensions.dashboardCardMobileHeight;
    }
    else {
      // standard 3-6 column layout at >520px width
      page = document.querySelector('.layout-page');
      cardHeight = dimensions.dashboardCardHeight;
    }

    return {page, cardHeight};
  };

  getCardDimensions = () => {
    let {cardWidth, cardHeight} = this.state;

    // setup card placeholder dimensions
    if(window.matchMedia(`(max-width: ${breakpoints.dashboardMobileMax}px)`).matches) {
      // mobile condensed dashboard view at <= 520px width
      cardWidth = dimensions.dashboardCardMobileWidthInner;
      cardHeight = dimensions.dashboardCardMobileHeightInner;

      const gridEl = document.querySelector('.layout-grid');

      if(gridEl) {
        // get DOM inner height + subtract card inner padding/borders
        cardWidth = gridEl.clientWidth - dimensions.dashboardCardTopPaddingBorders;
      }
    }
    else {
      cardWidth = dimensions.dashboardCardWidthInner;
      cardHeight = dimensions.dashboardCardHeightInner;
    }

    this.setState({
      cardWidth,
      cardHeight
    });
  };

  getPlaceholderCount = (props = this.props) => {
    // 1. determine anticipated items/row based on current screen resolution
    // 2. extrapolate total items needed in paginated request based on screen height, + 1 row of buffer
    // 3. fetch first batch of items, while using initial total to populate placeholders
    return new Promise((resolve, reject) => {
      const {showGroupings} = props;
      const {page, cardHeight} = this.getPageAndCardHeight();
      const {cardsPerRow, rowGap} = this.getRowDetails();
      const rowsToDisplay = Math.ceil(page.offsetHeight / (cardHeight + rowGap)) + 1;

      // TODO: this could be refactored out and this fn never called if on all groups/individual groups view
      // "all groups" view and individual groups (showGroupings) aren't paginated
      const placeholderCount = showGroupings
        ? RIVALS_LIMIT_UNLIMITED
        : cardsPerRow * rowsToDisplay;

      if(this._isMounted) {
        return this.setState({placeholderCount}, () => resolve(this.state.placeholderCount));
      }

      reject('DashboardView.getPlaceholderCount: component is unmounted');
    });
  };

  handleGroupLoaded = (group = null) => {
    const groupsLoaded = (this.state.groupsLoaded || []).slice();

    if(_.isEmpty(group) || groupsLoaded.includes(group.id)) {
      return;
    }

    this.setState({
      groupsLoaded: ReactUpdate(groupsLoaded, {$push: [group.id]})
    }, () => {
      console.log('DashboardView.handleGroupLoaded: groupId #%o loaded: %o, groupsLoaded: %o', group.id, group, this.state.groupsLoaded);
    });
  };

  handleCardVisible = (index = 0, group = null) => {
    // NOTE: placeholderCount is used below to represent 1 "page" of rivals to load/display
    const {placeholderCount, page} = this.state;
    const {rivalsOrder: order, totalRivals, activeGroup, showGroupings, rivals} = this.props;
    const rivalOptions = {
      order
    };

    if(showGroupings) {
      // TODO: future: add viewport-based pagination for rivalGroup requests in case of very large groups, similar to All Companies view
      Object.assign(rivalOptions, {
        rivalGroupId: group.id,
        page: 1,
        limit: placeholderCount || RIVALS_LIMIT_UNLIMITED   // placeholderCount may be 0 when navigating back to the dashboard from elsewhere
      });

      this.context.api.rivalsGet(rivalOptions).then(({rivals: groupRivals}) => {
        console.log('DashboardView.handleCardVisible: loaded rivals for groupId #%o %o: %o', group.id, group.name, groupRivals);
      });
    }
    else if(!showGroupings && placeholderCount) {
      // determine page/row card belongs to
      const {cardsPerRow} = this.getRowDetails();
      const startOfLastPartialPage = ((totalRivals - index) < placeholderCount) && (index === rivals.length);
      let nextPage = Math.ceil(index / placeholderCount);

      if((nextPage === page) && startOfLastPartialPage) {
        nextPage++;
      }

      // kick-off loading next batch of rivals
      if((nextPage > page) && (!(index % cardsPerRow) && (index <= totalRivals)) || startOfLastPartialPage) {
        // eager-set new page to prevent additional unnecessary loads until api call returns
        this.setState({page: nextPage});

        // retrieve paginated view; loads more on scroll
        Object.assign(rivalOptions, {
          page: nextPage,
          limit: placeholderCount || RIVALS_LIMIT_UNLIMITED
        });

        if(activeGroup) {
          // retrieve all rivals for currently selected group
          rivalOptions.rivalGroupId = activeGroup.id;
        }

        this.context.api.rivalsGet(rivalOptions).then(data => {
          this.setState({page: nextPage});

          console.log('DashboardView.handleCardVisible: loaded next batch of rivals: %o', data);
        });
      }
    }
  };

  handleToggleRivalDraftMode = ({id = 0, isDraft = false}, event) => {
    this._preventDefault(event);

    if(!isValidId(id)) {
      return;
    }

    const profileOptions = {
      id,
      isDraft: !isDraft
    };

    this.context.api.profileUpdate(profileOptions).then(updatedProfile => {
      this.props.updateRival(updatedProfile);
      console.log('DashboardView.handleToggleRivalDraftMode: profile #%o: %o, isDraft: %o', updatedProfile.id, updatedProfile, updatedProfile.isDraft);
    });
  };

  handleRemoveFromGroup = ({rivalId = 0, groupId = 0}, event) => {
    this._preventDefault(event);

    if((rivalId <= 0) || (groupId <= 0)) {
      return;
    }

    this.props.onToggleGroupMembership({rivalId, groupId, remove: true});
  };

  gotoPage = (url, event) => {
    this._preventDefault(event);

    this.props.history.push(url);

    // reset document scroll (otherwise chrome hackjacks on page change)
    setTimeout(window.scrollTo.bind(null, 0, 0), 1);
  };

  renderRivalCardsInGroups = allRivals => {
    const {user, rivalsLoaded, rivalGroupsLoaded, collapsedGroups, rivalGroups, onToggleGroup} = this.props;
    const groups = (rivalGroups || []).slice();
    const isCurator = userCanCurate({user});
    const sortedGroups = groups.reduce((nodes, group) => {
      const rivalsIdsInGroup = (group.rivals || []).map(r => r.id);
      const rivalsOfGroup = allRivals.filter(r => rivalsIdsInGroup.includes(r.id));
      let rivalCardsRegion;

      if(rivalGroupsLoaded && (rivalsIdsInGroup.length || !group.id)) {
        rivalCardsRegion = (
          <Grid>
            {this.renderRivalCards(rivalsOfGroup, group)}
          </Grid>
        );
      }
      else if(rivalsLoaded && isCurator) {
        rivalCardsRegion = (
          <div className="dashboard_empty-message">
            This group is currently empty.
          </div>
        );
      }

      if(rivalCardsRegion) {
        nodes.push(
          <DashboardGroup
            key={`group_${group.id}`}
            group={group}
            isActive={!collapsedGroups.includes(group.id)}
            modifier="title"
            label={group.name || '(Untitled)'}
            isLoaded={rivalsLoaded && rivalGroupsLoaded}
            onToggleEffect={onToggleGroup}
            filteredRivalIds={rivalsIdsInGroup}
            isGroupLoaded={this.state.groupsLoaded.includes(group.id)}
            onGroupLoaded={this.handleGroupLoaded}
            id={`group_${group.id}`}>
            {rivalCardsRegion}
          </DashboardGroup>
        );
      }

      return nodes;
    }, []);

    if(sortedGroups.length) {
      return (
        <div className="dashboard-groups">
          {sortedGroups}
        </div>
      );
    }

    if(rivalsLoaded && rivalGroupsLoaded && _.isEmpty(rivalGroups)) {
      return (
        <div className="no-companies">
          <h3>No groups found!</h3>
          <p>No groups have been created by your curator(s) yet.</p>
        </div>
      );
    }
  };

  renderRivalCards = (rivals, group = null) => {
    const {user, totalRivals, activeGroup, showGroupings, collapsedGroups, companyFilter} = this.props;
    const {cardWidth, cardHeight} = this.state;
    const {company = {}} = this.context.utils;
    const isCurator = userCanCurate({user});
    const rivalsRegion = rivals.map((rival, index) => {
      const {profile = null} = rival;

      if(_.isEmpty(profile)) {
        return;
      }

      const {profile: {id, isDraft, cardsCount, battlecardsCount, createdAt}} = rival;
      const profileBase = `/profile/${id}`;
      const profileLink = `${profileBase}/${isCurator ? 'battlecard/edit' : 'view'}`;
      const profileLinkClass = classNames('dashboard-card_footer_link', {
        'dashboard-card_footer_link--disabled': !cardsCount
      });
      const battlecardLink = `${profileBase}/battlecard/view`;
      const battlecardLinkClass = classNames('dashboard-card_footer_link', {
        'dashboard-card_footer_link--disabled': !battlecardsCount
      });
      const {forceProfileLinksOnDashboard = false} = company.companyData;
      const logoLink = forceProfileLinksOnDashboard ? profileLink : battlecardLink;
      const isNew = moment(user.lastSeenAt).diff(createdAt, 'seconds') <= 0;
      const onboardingAttrs = {};
      const uiBattlecardLink = (<li>
        <Link to={battlecardLink} className={battlecardLinkClass} tabIndex="-1">
          {profile.battlecardsCount || 'No'} {pluralize('battlecard', profile.battlecardsCount)}
        </Link>
      </li>);
      const uiProfileLink = (<li>
        <Link to={profileLink} className={profileLinkClass} tabIndex="-1">
          {cardsCount || 'No'} {pluralize('card', cardsCount)}
        </Link>
      </li>);
      let cardMenuRegion;

      if(index === 0) {
        onboardingAttrs.id = 'onboardBCStep1Target1';
      }

      if(userCanCurate({user})) {
        let removeFromGroupLink;

        if(activeGroup || (group && (group.id > 0))) {
          removeFromGroupLink = (
            <a href="#" onClick={e => this.handleRemoveFromGroup({rivalId: rival.id, groupId: (group?.id || activeGroup?.id)}, e)}>
              Remove from group
            </a>
          );
        }

        cardMenuRegion = (
          <MenuWrapper
            disableOnClickOutside={true}>
            {removeFromGroupLink}
            <a
              href="#"
              className="menu-wrapper_items_item"
              onClick={e => this.handleToggleRivalDraftMode({id, isDraft}, e)}>
              {isDraft ? 'Publish' : 'Unpublish'}
            </a>
            {/* TODO: add this back in once rival editing is implemented (#1806) */}
            {/*<a href="#">Rename/Edit Company</a>*/}
          </MenuWrapper>
        );
      }

      const footer = (
        <footer className="dashboard-card_footer">
          <ul>
            {uiBattlecardLink}
            {uiProfileLink}
          </ul>
        </footer>
      );

      return (
        <Observer key={`rival_${rival.id}`}>
          {isVisible => (
            <DashboardCard
              ref={node => this._setCardRef(rival.id, node)}
              key={`rival_${rival.id}`}
              id={`rival_${rival.id}${group ? `_group_${group.id}` : ''}`}
              isDraft={profile.isDraft}
              isNew={isNew}
              profileId={profile.id}
              cardWidth={cardWidth}
              cardHeight={cardHeight}
              isVisible={showGroupings || isVisible}
              onVisible={() => !showGroupings && this.handleCardVisible(index, group)}>
              <Link {...onboardingAttrs}
                to={logoLink}
                className="content dashboard-card_link"
                tabIndex="-1"
                onClick={e => this.gotoPage(logoLink, e)}>
                <div className="logo">
                  <CompanyLogo rival={rival} />
                </div>
                <h4 title={rival.name}>{rival.name}</h4>
              </Link>
              {cardMenuRegion}
              {footer}
            </DashboardCard>
          )}
        </Observer>
      );
    });

    const loadedRivalsCount = rivalsRegion.length;

    if(!companyFilter && (showGroupings || !_.isEmpty(rivals))) {
      let phCount = 0;

      if(showGroupings && !_.isEmpty(group)) {
        const groupRivals = group.rivals;

        // NOTE: make sure placeholders are loaded before we calculate outer container reference point (otherwise groups are collapsed)
        // "all groups" only populates total number of placeholders needed to complete visible group
        for(let i = loadedRivalsCount; i < groupRivals.length; i++, phCount++) {
          if(
            (i === loadedRivalsCount) &&
            this.state.groupsLoaded.includes(group.id) &&
            !collapsedGroups.includes(group.id)
          ) {
            // first placeholder in group
            rivalsRegion.push(
              <Observer key={`rival_ph_${phCount}`} rootMargin="0px 0px 300px 0px">
                {isVisible => (
                  <DashboardCard
                    key={`rival_ph_${phCount}`}
                    id={`ph_${phCount}`}
                    cardWidth={cardWidth}
                    cardHeight={cardHeight}
                    isVisible={isVisible}
                    onVisible={() => this.handleCardVisible(i, group)} />
                )}
              </Observer>
            );
          }
          else {
            rivalsRegion.push(
              <DashboardCard
                key={`rival_ph_${phCount}`}
                id={`ph_${phCount}`}
                cardWidth={cardWidth}
                cardHeight={cardHeight}
                isVisible={true} />
            );
          }
        }
      }
      else if(!showGroupings) {
        // fill current row (or add one additional row) with placeholders; scroll will trigger rival page load
        const {cardsPerRow} = this.getRowDetails();
        const {placeholderCount} = this.state;

        // make sure we always have at least one empty row at the end
        const fillerSlots = cardsPerRow - ((rivals.length % cardsPerRow) || cardsPerRow);

        const maxCardsToShow = Math.min(totalRivals, (rivals.length + fillerSlots + placeholderCount));

        for(let i = rivalsRegion.length; i < maxCardsToShow; i++) {
          rivalsRegion.push(
            <Observer key={`rival_ph_${i}`}>
              {isVisible => (
                <DashboardCard
                  id={`ph_${i}`}
                  cardWidth={cardWidth}
                  cardHeight={cardHeight}
                  isVisible={isVisible}
                  onVisible={() => this.handleCardVisible(i)} />
              )
              }
            </Observer>
          );
        }
      }
    }

    return rivalsRegion;
  };

  render() {
    const {user, rivals, rivalsLoaded, rivalGroupsLoaded, showGroupings, rivalGroups: groups, activeGroup, rivalsOrder} = this.props;
    const isCurator = userCanCurate({user});
    let noRivalsMessage;
    let dashboardRivals;

    if(rivalsLoaded && rivalGroupsLoaded && rivalsOrder &&
      (!showGroupings || !groups.length) &&
      (_.isEmpty(rivals) && (rivals !== null))
    ) {
      noRivalsMessage = (
        <div className="no-companies">
          <h3>No companies found!</h3>
          <p>
            Your organization&apos;s curator hasn&apos;t started tracking any companies with Klue just yet. Check back later for more!
          </p>
        </div>
      );
    }
    else if(isCurator && activeGroup && _.isEmpty(activeGroup.rivals)) {
      // empty group selected
      noRivalsMessage = (
        <div className="no-companies">
          <h3>No companies have been added to this group yet.</h3>
          <p>
            The <strong>{activeGroup.name}</strong> group is currently empty. Click <strong>Edit Groups</strong> above to add companies to it.
          </p>
        </div>
      );
    }

    if(showGroupings) {
      dashboardRivals = this.renderRivalCardsInGroups(rivals);
    }
    else {
      const rivalsGridRegion = (
        <Grid>
          {this.renderRivalCards(rivals)}
        </Grid>
      );
      let groupHeaderRegion;

      if(activeGroup) {
        if(rivals && rivals.length) {
          // show group heading for currently active group
          groupHeaderRegion = (
            <h4 className="dashboard-heading">{activeGroup.name}</h4>
          );

          dashboardRivals = (
            <div className="dashboard-active-group">
              {groupHeaderRegion}
              {rivalsGridRegion}
            </div>
          );
        }
      }
      else {
        dashboardRivals = rivalsGridRegion;
      }
    }

    return (
      <Page columns={1}>
        {noRivalsMessage}
        {!noRivalsMessage && dashboardRivals}
      </Page>
    );
  }

}

const mapDispatchToProps = dispatch => ({
  updateRival: profile => dispatch.rivals.updateRivalWithProfile(profile)
});

export default withRouter(connect(null, mapDispatchToProps)(DashboardView));
